<template>
  <div class="sampledetail">
    <van-nav-bar
      :title="Detail.sample_name"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="back"
    />
    <div class="title">基础信息</div>
    <van-cell-group class="mt10">
      <van-cell title="检测状态">
        <template #default>
          <van-tag v-if="Detail.inspect_status === '0'" round size="medium" type="warning">待检测</van-tag>
          <van-tag v-if="Detail.inspect_status === '1'" round size="medium" type="success">检测中</van-tag>
          <van-tag v-if="Detail.inspect_status === '2'" round size="medium" type="primary">初检完成</van-tag>
          <van-tag v-if="Detail.inspect_status === '3'" round size="medium" type="primary">检测完成</van-tag>
          <van-tag v-if="Detail.inspect_status === '4'" round size="medium" type="success">审批中</van-tag>
          <van-tag v-if="Detail.inspect_status === '5'" round size="medium" type="primary">审批完成</van-tag>
        </template>
      </van-cell>
      <van-cell title="报检类型" :value="Detail.type_name + ' [' + Detail.inspect_type + ']'" />
      <van-cell title="报检编号" :value="Detail.inspect_code + '-' + DetailCheck.id" />
      <van-cell title="报检时间" :value="Detail.inspect_time" />
      <van-cell title="标签打印" :value="Detail.print_count + '次'" />
    </van-cell-group>
    <div class="title">报检员信息</div>
    <van-cell-group class="mt10">
      <van-cell title="报检员姓名" :value="Detail.inspect_emp_name + ' [' + Detail.inspect_emp_id +']'" />
      <van-cell title="报检员部门" :value="Detail.inspect_dep_name + ' [' + Detail.inspect_dep_id +']'" />
    </van-cell-group>
    <div class="title">样品信息</div>
    <van-cell-group class="mt10">
      <van-cell title="产品编号" :value="Detail.sample_code" />
      <van-cell title="产品名称" :value="Detail.sample_name" />
      <van-cell v-if="Detail.sample_specs" title="产品规格型号" :value="Detail.sample_specs" />
      <van-cell v-if="Detail.inspect_num" title="产品数量" :value="Detail.inspect_num" />
      <van-cell title="报检批次" :value="Detail.inspect_batch" />
      <van-cell v-if="Detail.erp_purchase_arrival_no" title="到货单号" :value="Detail.erp_purchase_arrival_no" />
      <van-cell v-if="Detail.supplier_name" title="产品供应商" :value="Detail.supplier_name + ' [' + Detail.supplier_code +']'" />
      <van-cell v-if="Detail.contract_no" title="合同编号" :value="Detail.contract_no" />
      <van-cell v-if="Detail.inspect_remark" title="报检重要说明" :value="Detail.inspect_remark" />
      <van-cell v-if="Detail.car_number" title="车牌号" :value="Detail.car_number" />
      <van-cell title="图片附件" v-if="Detail?.attach_id?.length > 0" is-link @click="showPic" />
    </van-cell-group>
    <div v-if="Number(Detail.inspect_status) > 2">
      <div class="title" >检测情况</div>
      <van-cell-group class="mt10">
        <van-cell title="检测时间" :value="DetailCheck.check_time" />
        <van-cell title="检测设备" :value="DetailCheck.equipment_name" />
        <van-cell title="检样员" :value="DetailCheck.check_emp_name" />
        <van-cell v-if="Detail.is_inspect_recheck === '1'" title="是否复检" value="是" />
        <van-cell v-else title="是否复检" value="否" />
        <van-cell v-if="Detail.is_inspect_recheck === '1'" title="复检时间" :value="DetailCheck.inspect_recheck_time" />
        <van-cell v-if="Detail.is_inspect_recheck === '1'" title="复检设备" :value="DetailCheck.equipment_name" />
        <van-cell v-if="Detail.is_inspect_recheck === '1'" title="复检员" :value="DetailCheck.recheck_emp_name" />
        <van-cell title="检测结果" >
          <template #default>
            <van-tag 
              size="medium" 
              :type="DetailCheck.conclusion === '1' ? 'success' : 'danger'">
              {{ DetailCheck.conclusion === '1' ? '' : '不' }}合格
            </van-tag>
          </template>
        </van-cell>
        <van-cell v-if="DetailCheck.check_remark" title="初检备注" :value="DetailCheck.check_remark" />
        <van-cell v-if="DetailCheck.recheck_remark" title="复检备注" :value="DetailCheck.recheck_remark" />
      </van-cell-group>
    </div>
    <div class="title">检测项目</div>
    <ve-table 
      ref="tableRef"
      :columns="columns" 
      :table-data="DetailCheck.check_items"
      :border-x="true" 
      :border-y="true" 
      :max-height="300" 
      :fixed-header="true"
      rowKeyFieldName="rowKey" />
    <div v-if="+Detail.inspect_status > 4">
      <div class="title">让步接收情况</div>
      <p class="gray plr16">申请说明:{{Detail.check.concession_acceptance_reason}}</p>
      <van-cell title="审批人"  input-align="right" readonly :value="Detail.check.approval_emp_name" />
      <van-cell title="备注"  input-align="right" readonly v-model="Detail.check.approval_remark" />
      <van-cell title="审批附件" />
      <van-cell v-if="Detail.check?.attach_id?.length > 0">
        <span style="text-align: center; width: 33.33%; display: inline-block;" v-for="item in Detail.check.attach_id" :key="item">
          <van-image width="100" height="100" :src="item" @click="bigImgFj()" />
        </span>
      </van-cell>
      <van-cell title="审批结果" input-align="right">
        <template>
          <van-tag v-if="+Detail.check.is_concession_acceptance === 0" type="danger" size="medium">不同意</van-tag>
          <van-tag v-else type="success" size="medium">同意</van-tag>
        </template>
      </van-cell>
      <van-cell title="时间"  input-align="right" readonly v-model="Detail.check.approval_time" />
      <!-- <van-steps direction="vertical" :active="0" v-if="+Detail.inspect_status === 5">
        <van-step>
          <h6>[<span v-if="Detail.check.is_concession_acceptance === '0'">不同意</span><span v-else>同意</span>] {{Detail.check.approval_remark}}</h6>
          <p>{{Detail.check.approval_emp_name}} {{Detail.check.approval_time}}</p>
        </van-step>
      </van-steps> -->
    </div>
    <div class="mt10 btn-r">
      <van-button class="ml5" type="default"
        round 
        plain 
        size="small" 
        @click="toUrl">返回列表</van-button>
      <van-button v-if="Detail.inspect_status === '3' && Detail.check.conclusion === '0' && Detail.is_inspect_recheck === '0'" 
        class="ml5" type="default"
        round 
        plain 
        size="small"
        @click="check(DetailCheck.id)">申请复检</van-button>
      <van-button v-if="Detail.inspect_status === '3' && Detail.check.conclusion === '0'"
        class="ml5" type="default"
        round 
        plain 
        size="small" 
        @click="apply">让步申请</van-button>
      <van-button v-if="Detail.is_cancel !== '1'"
        class="ml5" type="default"
        round
        plain
        size="small"
        @click="showQRcode">打印标签</van-button>
      <van-button v-if="Number(Detail.inspect_status) > 2"
        class="ml5" type="default"
        round
        plain
        size="small"
        @click="toReport">质检报告</van-button>
    </div>

<!-- 让步申请 弹窗 -->
    <van-dialog v-model="applyshow" title="让步申请" :showConfirmButton="false">
      <van-form @submit="applySubmit">
        <van-field
          readonly
          clickable
          required
          name="inspect_emp_id"
          :value="approvalParams.approval_emp_name"
          label="审批人"
          placeholder="点击选择审批人"
          @click="showApproval"
          :rules="[{ required: true, message: '请选择审批人' }]"
        />
        <van-field type="textarea" v-model="approvalParams.concession_acceptance_reason"
          autosize
          required
          rows="2"
          show-word-limit
          maxlength="120"
          label=""
          placeholder="申请理由" 
          :rules="[{ required: true, message: '请填写申请理由' }]"
        />
        <van-row gutter="20" class="btgroup" type="flex" justify="center">
          <van-col span="8"><van-button round block plain size="small" type="default" native-type="button" @click="applyshow = false">取消</van-button></van-col>
          <van-col span="16"><van-button round block size="small" type="primary" native-type="submit">提交</van-button></van-col>
        </van-row>
      </van-form>
    </van-dialog>

<!-- 让步申请 审批人 弹窗 -->
    <van-popup v-model="ApprovalStatus" position="bottom" round :style="{ height: '50%' }">
      <van-picker title="审批人选择"
        show-toolbar
        :columns="Approvals"
        value-key="username"
        @confirm="ApprovalConfirm"
        @cancel="ApprovalStatus = false"
      />
    </van-popup>
 
<!-- 标签纸打印部分 弹窗 -->
    <van-popup v-model="QRcodeShow" position="bottom">
      <div class="label" id="printMe">
        <van-row class="qrlist" type="flex">
          <van-col span="24" class="qr-tt"><p class="tac">{{Detail.sample_name}}</p></van-col>
          <van-col span="11" class="qr-lt">
            <p class="fs16">批号：{{Detail.inspect_batch}}</p>
            <p v-if="Detail.inspect_num">数量：{{Detail.inspect_num}}</p>
            <p>报检：{{Detail.inspect_emp_name}}</p>
            <p>日期：{{Detail.inspect_time}}</p>
            <p v-if="Detail.car_number" >车牌号：{{Detail.car_number}}</p>
            <p v-if="Detail.other_remark" >说明：{{Detail.other_remark}}</p>
            <p v-if="Detail.contract_no">其他：{{Detail.contract_no}}</p>
          </van-col>
          <van-col span="13" class="qr-rt">
            <QRcode :width="code.with" :height="code.height" :content="code.content" :load="false"></QRcode>
            <p class="tc">ID：{{Detail.check.id}}</p>
          </van-col>
          <van-col v-if="Detail.supplier_name" span="24" class="qr-bt"><p>供应商：{{Detail.supplier_name}}</p></van-col>
        </van-row>
      </div>
      <van-button class="printBtn" round plain type="default" v-print="print">立即打印</van-button>
    </van-popup>

  </div>
</template>
<script>
import Vue from 'vue'
import { Col, Row, Cell, CellGroup, Step, Steps, Button, Toast, ImagePreview, Dialog, Field, NavBar, Popup, Tag } from 'vant'
import { mapActions } from 'vuex'

Vue.use(Col)
Vue.use(Row)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Button)
Vue.use(Toast)
Vue.use(ImagePreview)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(Tag)

export default {
  data() {
    const that = this
    return {
      id: '',
      user: {},
      columns: [{
          field: "check_name", key: "a", title: "检测项", align: "center"
        }, {
          field: "check_standard", key: "b", title: "参考指标", align: "center"
        }, {
          field: "check_value", key: "c", title: "检测指标", align: "center"
        }, {
          field: "single_conclusion",
          key: "d",
          title: "单项结论",
          align: "center",
          renderBodyCell: ({ row, column }) => {
            if (column.field === "single_conclusion") {
              if (Number(that.Detail.inspect_status) > 2) {
                return Number(row.single_conclusion) === 0 ? '不合格': '合格'
              } else {
                return '未检测'
              }
            }
          }
        }],
      print: {
        id: 'printMe',
        popTitle: '打印标签', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '打印预览', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 999, // 预览窗口的z-index，默认是20002，最好比默认值更高               
        standard: '',
        extarCss: '',
        openCallback() { 
          that.$post('inspect/print-record', 'api', { id: that.id }).then(res => {
            console.log(res)
            if (res.data) {
              that.$set(that.Detail, 'print_count', Number(that.Detail.print_count)  + 1)
            } else {
              that.$notify({ type: 'warning', message: res.message })
            }
          })
          // console.log('执行打印了！') 
        }, // 调用打印时的callback
      },
      cellSpanOption: {
        bodyCellSpan: this.bodyCellSpan,
        footerCellSpan: this.footerCellSpan,
      },
      Detail: {},
      DetailCheck: {},
      footerData: [],
      Approvals: [],
      approvalParams: {
        id: '',
        approval_emp_id: '',
        approval_emp_name: '',
        concession_acceptance_reason: ''
      },
      picshow: false,
      applyshow: false,
      checkshow: false,
      applyform: '',
      checkform: '',
      code: {
        with: '120',
        height: '120',
        content: ''
      },
      ApprovalStatus: false,
      QRcodeShow: false
    }
  },
  created: function () {
    this.id = this.$route.query.id
    this.getDetail()
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      this.user = user
    } else {
      this.asyncUser().then(res => {
        this.user = res
      })
    }
  },
  methods: {
    ...mapActions('router', ['asyncUser']),
    getDetail() {
      const that = this
      this.$post('inspect/get-inspect-detail', 'api', {
        id: this.id
      }).then(res => {        
        const Detail = res.data
        const DetailCheck = res.data.check
        for (let item in Detail) {
          if (item.includes('time')) {
            Detail[item] = that.$common.initDate(Detail[item])
          }
        }
        for (let item in DetailCheck) {
          if (item.includes('time')) {
            DetailCheck[item] = that.$common.initDate(DetailCheck[item])
          }
        }
        if (DetailCheck.equipment_items !== null) {
          DetailCheck.equipment_name = DetailCheck.equipment_items.map(res => { return res.equipment_name + ',' })
        } else {
          DetailCheck.equipment_name = '暂无数据'
        }
        Detail.type_name = Detail.type.type_name
        this.Detail = Detail
        this.DetailCheck = DetailCheck
      }, err => {
        Toast.fail(err.message)
      })
    },
    showPic() {
      ImagePreview(this.Detail.attach_id)
    },
    apply() {
      this.applyshow = true
    },
    check(id) {
      Dialog.alert({
        title: '复检确认',
        message: '您确定要提交复检请求吗？',
        theme: 'round-button',
        showCancelButton: true
      }).then(() => {
        this.$post('inspect/apply-recheck', 'api', {
          id: id
        }).then(res => {
          this.$notify({ type: 'success', message: res.message })
          this.checkshow = false
          this.Detail.is_inspect_recheck = '1'
        })
      }).catch(() => {
        // on cancel
        Dialog.close
      })
      // this.checkshow = true
    },
    showApproval() {
      this.ApprovalStatus = true
      this.getApproval()
    },
    ApprovalConfirm(val) {
      this.approvalParams.approval_emp_id = val.uid
      this.approvalParams.approval_emp_name = val.username
      this.ApprovalStatus = false
    },
    applySubmit() {
      this.approvalParams.id = this.id
      if (this.approvalParams.concession_acceptance_reason) {
        this.$post('inspect/apply-compromise-receive', 'api', this.approvalParams).then(res => { 

          this.$notify({ type: 'success', message: res.message })
          this.applyshow = false
          this.Detail.inspect_status = '4'
        })
      } else {
        this.$notify({ type: 'warning', message: '请填写提交缘由' })
      }
    },
    bodyCellSpan({ row, column, rowIndex }) {
      // const rowspan = this.DetailCheck.check_items.length
      if (column.field === "conclusion_name") {
        if (rowIndex === 0) {
          return {
            rowspan: this.DetailCheck?.check_items?.length,
            colspan: 1,
          }
        }else if (rowIndex >= 1) {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    footerCellSpan({ row, column, rowIndex }) {
      if (column.field === "check_name") {
        if (rowIndex === 0) {
          return {
            rowspan: 1,
            colspan: this.printColumns.length,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    // print() {
    //   dd.ready(() => { 
    //     dd.biz.microApp.openApp({
    //       agentId: '1813650374',
    //       appId: '',
    //       corpId: '',
    //       onSuccess: function (result) {
    //         dd.device.notification.alert({
    //           message: result,
    //           title: "",
    //           buttonName: "收到",
    //           onSuccess : function(res) {
    //             // 调用成功时回调
    //             console.log(res)
    //           },
    //           onFail : function(err) {
    //             // 调用失败时回调
    //             console.log(err)
    //           }
    //         })
    //       },
    //       onFail: function (err) {
    //         dd.device.notification.alert({
    //           message: err,
    //           title: "",
    //           buttonName: "失败",
    //           onSuccess : function(res) {
    //             // 调用成功时回调
    //             console.log(res)
    //           },
    //           onFail : function(err) {
    //             // 调用失败时回调
    //             console.log(err)
    //           }
    //         })
            
    //       }
    //     })
    //   })
    // },
    getApproval() {
      this.$get('user/get-user-list', 'api', {
        page: 1,
        offset: 100,
        is_accept_auth: 1
      }).then(res => {
        const datas = res.data.data.filter(ref => ref.uid !== this.user.dd_data.dd_user_id)
        this.Approvals = datas
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    showQRcode() {
      if (this.$common.isMobilePhone()) {
        Dialog({ message: '请使用PC端打印' })
        return false
      }
      this.QRcodeShow = true
      this.code.content = 'http://lims.cicg.tech/#/analysisdetail?type=1&id=' + this.DetailCheck.id
      // let params = {
      //   userId: '123', //用户id
      //   userName: 'Bob', //用户名
      //   userNumber: 'sas123', //工号
      // }
      // this.content = JSON.stringify(params)
    },
    back() {
      // window.history.go(-1)
      this.$router.push({path: '/samplelist'})
    },
    toUrl() {
      this.$router.push({ path: '/samplelist' })
    },
    toReport() {
      this.$router.push({ path: '/report', query:{ id: this.id} })
    }
  }
}
</script>

<style>
.sampledetail{
  padding-bottom: 60px;
}
.title{
  font-size: 14px;
  color: #565656;
  margin: 10px 16px;
}
.van-step__icon h6, 
.van-step__title h6{
  margin: 0;
}
.title+p.gray{
  background-color: #fff;
}
.btn-r{
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}
#report{
  display: none;
  font-size: 12px;
}
.label{
  max-width: 320px;
  width: 90%;
  margin: 10px auto;
  border-radius: 6px;
  overflow: hidden;
  transform: scale(1, 1);
 }
.label h3{
  text-align: center;
  font-size: 20px;
  line-height: 1.628em;
  margin: 0 auto;
  color: #000000;
}
.qrlist{
  width: 100%;
  font-size: 12px;
  margin-top: 2px;
  padding: 4px 0;
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
}
.qrlist p{
  letter-spacing: -1px;
  line-height: 1.2em;
  font-size: 12px;
  color: #000000;
  margin: 0;
  padding: 0;
  width: 100%;
  /* word-wrap: break-word;
  word-break: break-all; */
  white-space: nowrap;
}
.qrlist .qr-bt{
  margin-top: -36px;
  margin-left: -20px;
  transform: scale(0.7, 0.7);
}
.qrlist .qr-lt{
  transform: scale(0.72, 0.72);
  margin-top: -18px;
  margin-left: -10px;
  position: relative;
  z-index: 1;
}
.qrlist .qr-lt p.fs16{
  font-size: 16px;
}
.qrlist .qr-rt{
  transform: scale(0.6, 0.6);
  margin-top: -14px;
  position: relative;
  z-index: 10;
}
.printBtn{
  width: 60%;
  margin: 5px 20% 15px;
}
.btgroup{
  padding:10px 10px 20px;
}
/*去除页眉页脚*/
@page{
  size:  auto;   /* auto is the initial value */
  margin: 2mm;  /* this affects the margin in the printer settings */
}
html{
  background-color: #FFFFFF;
  margin: 0;  /* this affects the margin on the html before sending to printer */
}
/*去除页眉页脚*/
</style>
